<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="modelTable.content"
      :search="search"
      height="auto"
      fixed-header
      item-key="email"
      dense
      single-line
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              :disabled="loading_search"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.available`]="{ item }">
        <v-switch
          v-if="userStatus"
          color="success"
          class="hand"
          hide-details
          dense
          @change="confirmchangeStatus(item)"
          inset
          v-model="item.available"
        >
        </v-switch>

        <div v-if="!userStatus">
          {{ item.available ? "Activo" : "Inactivo" }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDetail">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detailUser(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye mr-1"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="resetPassword">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="confirmResetPassword(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-rotate-ccw"></i>
            </span>
          </template>
          <span>{{ $t("table.reset_password") }}</span>
        </v-tooltip>
        <v-menu offset-x left v-if="isBranchOffice || userUpdate">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <i class="feather icon-more-vertical"></i>
                </v-btn>
              </template>
              <span>Más opciones</span>
            </v-tooltip>
          </template>

          <!-- @click="getPatient(item)" -->
          <v-list dense nav>
            <v-list-item v-if="userUpdate" @click="changeAction(3, item)">
              <v-list-item-content>
                <v-list-item-title>
                  <i class="mdi mdi-square-edit-outline mr-1"></i>
                  Editar información de usuario</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isBranchOffice" @click="changeAction(4, item)">
              <v-list-item-content>
                <v-list-item-title>
                  <i class="feather icon-clipboard mr-1"></i>
                  Editar asignación sucursales</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          v-if="!isDetail && !resetPassword && !userUpdate && !isBranchOffice"
        >
          -
        </div>
      </template>
    </v-data-table>
    <ButtonTableComponent v-if="true" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
/**
 * @component TableUserComponent
 * @desc Este es el componente de TableUserComponent que muestra la tabla principal del modulo
 * @author Jesus Teran
 */
export default {
  props: {
    headers: {},
    resetPassword: false,
    userUpdate: false,
    userStatus: false,
    isDetail: false,
    isBranchOffice: false,
    confirmchangeStatus: Function,
    detailUser: Function,
    confirmResetPassword: Function,
    changeAction: Function,
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      page: 1,
      options: {
        sortDesc: [true],
      },
      loading: false,
      loading_search: false,
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /**
     * Un método que se llama cuando cambia la paginación.
     * @method
     */
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      this.pagesAtributes.currentPage = this.options.page - 1;
      this.pagesAtributes.itemsPerPage = this.options.itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    /**
     * Método que se llama cuando se utiliza la barra de búsqueda.
     * @method
     */
     async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    reloadPages() {
      this.page = 1;
    },
    excelTable() {
      this.$emit("excel");
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-text-field__suffix {
    font-size: 13px !important;
  }
}
</style>
