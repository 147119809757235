<template>
  <div class="col-md-12 text-right">
    <button
      class="btn btn-outline-success"
      type="button"
      @click="confirm"
      :disabled="validator || sending"
    >
      <i class="feather icon-save mr-2"></i>{{ buttonTitle }}
    </button>
  </div>
</template>

<script>
/**
 * @component ButtonAction
 * @desc Este es el common de ButtonAction que genera un boton global. Su utilización depende del desarrollo que se quiere realizar
 * normalmente se utiliza cuando se realiza la separación de funcionalidad en modulos especificos
 * @author Jesus Teran
 */
export default {
  props: {
     /** @vprop {Boolean} [sending='false'] - Se le envia para habilitar o desabilitar al momento de realizar la peticion del API  */
     /** @vprop {String} [buttonTitle=""] - Se envia para definir el texto que tendra el boton */
     /** @vprop {Boolean} [validator='true'] - Se envia para habilitar o desabilitar al momento de que se esta validando un formulario  */
     /** @vprop {Function} onClick - required - Es la funcion para emitir el '@click' a la vista principal que se presiono el boton   */
    sending: {
      default: false,
    },
    buttonTitle: {
      default: "",
    },
    validator: { default: true },
    onClick: Function,
  },
  methods: {
     /**
     * @method
     * @desc Funcion que emite el metodo '@click' a la vista donde ha sido llamado
     */
    confirm() {
      this.onClick();
    },
  },
};
</script>
