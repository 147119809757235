<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue-fmedical">
        <v-toolbar-title class="pt-4">
          <p class="text-center">
            <i class="feather icon-user-check mr-2"></i> Detalle del usuario
          </p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-md-12 pb-0">
            <div class="col-md-12 pb-0">
              <p class="pt-3">
                <i class="fa fa-info mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>Información</span>
                </strong>
              </p>
            </div>
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <p>
                      <i class="feather icon-user mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> Nombre del usuario:</span>
                      </strong>
                      {{ model.name }}
                      {{ model.lastName }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <p>
                      <i class="feather icon-mail mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> Correo electrónico:</span>
                      </strong>
                      {{ model.email }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <p>
                      <i
                        class="feather icon-lock mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Rol asignado:</span>
                      </strong>
                      {{ model.roleName }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <p>
                      <i
                        class="feather icon-clipboard mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Sucursal principal:</span>
                      </strong>
                      {{ model.mainBranchOfficeName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template>
            <div class="col-md-12 pt-5">
              <v-tabs
                class="br"
                background-color="#263A5B"
                dark
                v-model="tab"
                fixed-tabs
                icons-and-text
              >
                <v-tab class="pr-7" href="#1">
                  <v-badge color="transparent">
                    <span class="mr-1"> Sucursales asignadas</span>
                  </v-badge>
                  <v-icon class="pb-2">feather icon-file-text</v-icon>
                </v-tab>
                <v-tab class="pr-4" href="#2">
                  <v-badge color="transparent">
                    <span class="mr-1"> Historico de inicio de sesión</span>
                  </v-badge>
                  <v-icon class="pb-2">feather icon-message-square</v-icon>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item value="1" eager>
                  <div class="col-md-12 pr-0 pl-0 pb-6" v-if="dialog">
                    <v-data-table
                      :headers="headers_branch"
                      :items="model.branchOffices"
                      :search="search"
                      fixed-header
                      height="auto"
                      :footer-props="{
                        'items-per-page-text': $t('table.item_table'),
                        'items-per-page-all-text': $t('table.all'),
                        'items-per-page-options': [15, 30, 50, 100],
                      }"
                      :loading-text="$t('table.loading')"
                      :no-data-text="$t('table.no_results_available')"
                      :no-results-text="$t('table.no_results_search')"
                      dense
                    >
                      <template v-slot:top>
                        <div class="row justify-end">
                          <div
                            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right"
                          >
                            <v-text-field
                              v-model="search"
                              :label="$t('table.search')"
                            ></v-text-field>
                          </div>
                        </div>
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                          v-if="item.status"
                          color="#048e61"
                          dark
                          class="m-1 time"
                        >
                          Activa
                        </v-chip>

                        <v-chip v-else dark class="m-1 time" color="#ff3f3f"
                          >Inactiva</v-chip
                        >
                      </template>
                    </v-data-table>
                    <ButtonPrintComponent />
                  </div>
                </v-tab-item>
                <v-tab-item value="2" eager>
                  <div class="col-md-12 pr-0 pl-0 pb-6" v-if="dialog">
                    <v-data-table
                      :headers="headers_history"
                      :items="model.sessionHistory"
                      :search="search"
                      fixed-header
                      height="auto"
                      :footer-props="{
                        'items-per-page-text': $t('table.item_table'),
                        'items-per-page-all-text': $t('table.all'),
                        'items-per-page-options': [15, 30, 50, 100],
                      }"
                      :loading-text="$t('table.loading')"
                      :no-data-text="$t('table.no_results_available')"
                      :no-results-text="$t('table.no_results_search')"
                      dense
                    >
                      <template v-slot:top>
                        <div class="row justify-end">
                          <div
                            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right"
                          >
                            <v-text-field
                              v-model="search"
                              :label="$t('table.search')"
                            ></v-text-field>
                          </div>
                        </div>
                      </template>
                      <template v-slot:[`item.accessDate`]="{ item }">
                        {{ formDate(item.accessDate) }}
                      </template>
                    </v-data-table>
                    <ButtonPrintComponent />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </template>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @component DetailUserComponent
 * @desc Este es el componente de DetailUserComponent es el que muestra el detalle del usuario
 * @author Jesus Teran
 */
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import {
  BRANCH_OFFICE_USER,
  USER_SESSION_HISTORY,
} from "@/core/DataTableHeadersLocale";
import moment from "moment";
export default {
  props: {
    model: {
      required: true,
      default: {},
    },
  },
  data() {
    return {
      search: "",
      tab: 1,
      dialog: false,
      headers_branch: BRANCH_OFFICE_USER(),
      headers_history: USER_SESSION_HISTORY(),
    };
  },
  methods: {
    /**
     * Es llamado para ocultar o mostrar el modal del detalle de usuario
     * @method
     * @param {Boolean} payload para ocultar o mostrar el modal de detalle
     */
    dialogShow(payload) {
      this.dialog = payload;
    },
    /**
     * Da formato a la fecha obtenida de la base de datos
     * @method
     * @param {Object} item la informacion de la fecha a la que le dara formato
     */
    formDate(item) {
      return moment(item).format("LLLL");
    },
  },
  components: {
    ButtonPrintComponent,
  },
};
</script>

<style scoped>
.time {
  font-family: "Roboto-Regular";
}
</style>
