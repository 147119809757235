<template>
  <div>
    <div id="containerbar">
      <div class="rightbar">
        <div class="breadcrumbbar">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
              <h4 class="page-title">{{ $t("user.supplies") }}</h4>
              <div class="breadcrumb-list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dashboard">{{ $t("user.home") }}</a>
                  </li>
                  <li class="breadcrumb-item">
                    {{ $t("user.administration") }}
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">{{ $t(title) }}</a>
                  </li>
                </ol>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
              <button
                class="btn btn-outline-success"
                v-if="
                  showAction(1) &&
                    withPermissionName(permissions.ADM_USE_ADD_01)
                "
                @click="changeAction(2)"
              >
                <i class="feather icon-plus mr-1"></i>
                {{ $t("global.create_user") }}
              </button>
              <button
                class="btn btn-outline-success"
                v-if="showAction(2) || showAction(3) || showAction(4)"
                @click="changeAction(1)"
              >
                <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
              </button>
            </div>
          </div>
        </div>
        <div class="contentbar">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <AlertMessageComponent ref="alertMessageComponent" />
                <div class="card-header">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title">
                        <i class="feather icon-users mr-2"></i
                        >{{ $t(titleAction) }}
                      </h5>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <v-autocomplete
                        v-show="showAction(1)"
                        v-model="rolesFilter"
                        :items="roles"
                        dense
                        outlined
                        class="br"
                        clearable
                        label="Rol"
                        data-vv-name="select"
                        v-on:change="filterRol()"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div v-show="showAction(1)">
                  <TableUserComponent
                    ref="tableUserComponent"
                    :headers="headers"
                    :modelTable="listUsers"
                    :pagesAtributes="pagesAtributes"
                    :confirmchangeStatus="updateStatus"
                    :confirmResetPassword="resetPassword"
                    :changeAction="changeAction"
                    :methodPage="listAllUser"
                    :detailUser="detailUser"
                    @excel="excel"
                    :resetPassword="
                      withPermissionName(permissions.ADM_USE_RES_04)
                    "
                    :userUpdate="
                      withPermissionName(permissions.ADM_USE_EDIT_03)
                    "
                    :userStatus="
                      withPermissionName(permissions.ADM_USE_EDIT_STA_05)
                    "
                    :isDetail="withPermissionName(permissions.ADM_USE_DETA_02)"
                    :isBranchOffice="
                      withPermissionName(permissions.ADM_USE_EDIT_BRANCH_07)
                    "
                  />
                </div>

                <div v-if="showAction(2)">
                  <div class="col-12 pb-0 pl-5">
                    <h6>
                      <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                      Información del usuario
                    </h6>
                  </div>
                  <FormUserComponent
                    ref="formUser"
                    :model="modelUser"
                    :roles="roles"
                    :showAction="showAction"
                    :main="main"
                  />
                  <div class="col-md-12">
                    <div class="pb-5">
                      <h6>
                        <v-icon class="pb-1">mdi-numeric-3-box-outline</v-icon>
                        Asignación de sucursales
                      </h6>
                    </div>
                    <BranchOfficeCommon
                      ref="get_listBranchOfficeUser"
                      :userListBranchOffice="[]"
                      :user_modules="true"
                      :main="main"
                    />
                  </div>
                  <ButtonAction
                    :onClick="onAction"
                    :buttonTitle="buttonTitle"
                    :validator="false"
                    :sending="false"
                  />
                </div>
                <div v-if="showAction(3)">
                  <div class="col-12 pb-0 pl-5">
                    <h6>
                      <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                      Información del usuario
                    </h6>
                  </div>
                  <FormUserComponent
                    ref="formUser"
                    :model="modelUser"
                    :roles="roles"
                    :showAction="showAction"
                    :main="main"
                  />

                  <ButtonAction
                    :onClick="onAction"
                    :buttonTitle="buttonTitle"
                    :validator="false"
                    :sending="false"
                  />
                </div>
                <div v-if="showAction(4)">
                  <div class="col-12">
                    <h6>
                      <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                      Información complementaria
                    </h6>
                  </div>
                  <FormUserComponent
                    ref="formUser"
                    :model="modelUser"
                    :roles="roles"
                    :showAction="showAction"
                    :main="main"
                  />

                  <div class="col-12">
                    <div class="pb-5">
                      <h6>
                        <v-icon class="pb-1">mdi-numeric-2-box-outline</v-icon>
                        Asignación de sucursales
                      </h6>
                    </div>
                    <BranchOfficeCommon
                      ref="get_listBranchOfficeUser"
                      :userListBranchOffice="modelUser.branchOffices"
                      :user_modules="true"
                      :main="main"
                    />
                  </div>
                  <ButtonAction
                    :onClick="onAction"
                    :buttonTitle="buttonTitle"
                    :validator="false"
                    :sending="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DetailUserComponent
      ref="detailUserComponent"
      :model="modelUser"
      :dialog="dialog"
    />
  </div>
</template>

<script>
/**
 * @module UserView
 * @desc Usuarios
 * @author Jesus Teran
 */
// Vuex
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";

import TableUserComponent from "@/components/user/TableUserComponent";
import FormUserComponent from "@/components/user/FormUserComponent";
import DetailUserComponent from "@/components/user/DetailUserComponent";

import { UserRequest, ExcelRequest, RolRequest } from "@/core/request";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { USERS } from "@/core/DataTableHeadersLocale";
import { SwalConfirm } from "@/core/SwalAlert";

import ButtonAction from "@/common/button/ButtonAction";
import BranchOfficeCommon from "@/common/branchOffice/BranchOfficeCommon";

export default {
  data() {
    return {
      title: "user.users",
      /** * @member {Object} listUsers se guarda el todal de elementos y paginas de la lista de usuarios asi como la misma. */
      listUsers: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      headers: USERS(),
      search: "",
      action: 1,
      /** * @member {Object} main guarda la sucursal principal seleccionada en el formulario tanto al modificar como al registrar */
      main: {
        mainBranchOffice: [],
      },
      /** * @member {Object} modelUser guarda la información que se utiliza para el registro, modificación y detalle del usuario */
      modelUser: {
        mainBranchOffice: "",
        idMainBranchOffice: "",
        role: "",
        userId: "",
        email: "",
        name: "",
        lastName: "",
        password: "",
        passwordConfirm: "",
        branchOffices: [],
        sessionHistory: [],
      },

      branchOffice: {
        idBranchoffice: 0,
      },
      dialog: false,
      roles: [],
      /** * guarda la informacion utilizada en la tabla para realizar la paginación el filtrado de datos
       * { currentPage: 0 (indica en la pagina que se encuentra), itemsPerPage: 15 (elemento por pagina), search: "", sortBy: "" (el elemento al filtrar),
        sortDesc: false,
        sortRol: "" (filtrado de rol) }
       *  @member {Object} pagesAtributes  */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
        sortRol: "",
      },
      rolesFilter: "",
      loading: false,
      roles_loading: false,
      permissions: PermissionConstants,
    };
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      const title = {
        1: "user.users",
        2: "user.register_user",
        3: "user.edit_user",
        4: "user.information_user",
      };
      return title[this.action] || "Listado";
    },
    buttonTitle() {
      const buttonTitle = {
        2: "Registrar usuario",
        3: "Modificar información",
        4: "Modificar asignación",
      };
      return buttonTitle[this.action] || "";
    },
  },
  methods: {
    // Funcion para el cambio de sucursal global en el state
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    showAction(action) {
      return this.action == action;
    },
    // Función de alert global para todas las vistas
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    async changeAction(action, item) {
      this.cancel();
      switch (action) {
        case 1:
          this.action = action;
          break;
        case 2:
          this.action = action;
          await this.listAllRoles();
          break;
        case 3:
        case 4:
          const userId = this.$UtilFront.getDataUser().idUser;
          if (item.userId == userId) {
            return this.alert(
              "error",
              "No puedes modificar tu propia asignación de sucursales."
            );
          }
          const typeUpdate = action == 3 ? 1 : 2;
          if (action == 4) {
            await this.listAllRoles();
          }
          try {
            await this.getInfoUserEdit(item, typeUpdate);
            this.action = action;
          } catch (error) {
            this.loading.hide();
            this.alert("error", error);
          }
          break;
      }
    },
    cancel() {
      this.modelUser = {
        mainBranchOffice: "",
        idMainBranchOffice: "",
        role: null,
        userId: "",
        email: "",
        name: "",
        lastName: "",
        password: "",
        passwordConfirm: "",
        branchOffices: [],
        sessionHistory: [],
      };
    },
    async onAction() {
      let isValid = await this.$refs.formUser.validateForm();
      if (isValid) {
        switch (this.action) {
          case 2:
            this.addUser();
            break;
          case 3:
            this.updateInfoUser();
            break;
          case 4:
            this.updateInfoUserBranchOffice();
            break;
          default:
            break;
        }
      } else {
        this.$UtilFront.windowScrollTo();
      }
    },
    /**
     * Realiza la filtración por rol y ordenar los datos en función del valor seleccionado.
     * @method
     */
    async filterRol() {
      this.pagesAtributes.sortRol =
        this.$UtilFront.isNull(this.rolesFilter) ||
        this.$UtilFront.isEmpty(this.rolesFilter)
          ? ""
          : this.rolesFilter;
      await this.listAllUser(this.pagesAtributes);
      this.$refs.tableUserComponent.reloadPages();
    },
    /**
     * Realiza la petición para obtener la información de usuario a modificar
     * @method
     * @param {Object} item la información del usuaria a obtener
     * @param {Number} typeUpdate define el tipo de petición 1:obtiene la información del usuario 2:obtine las sucursales asignadas, rol, sucursal principal
     */
    async getInfoUserEdit(item, typeUpdate) {
      this.loading = UtilFront.getLoadding();
      await UserRequest.detailUserUpdate({
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
        userId: item.userId,
        typeUpdate: typeUpdate,
      })
        .then((res) => {
          if (typeUpdate == 1) {
            this.modelUser = res.data;
          } else {
            let { data } = res;
            this.modelUser.branchOffices = data.branchOfficesAssigned;
            this.modelUser.role = data.roleId;
            this.modelUser.mainBranchOffice = data.mainBranchOffice;
            this.modelUser.userId = data.userId;
          }
        })
        .catch((error) => {
          this.changeAction(1);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Realiza la petición para actualizar la información del usurio
     * @method
     */
    async updateInfoUser() {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html: "La información del usuario sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let data = {
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
        userId: this.modelUser.userId,
        email: this.modelUser.email,
        name: this.modelUser.name,
        lastName: this.modelUser.lastName,
      };
      await UserRequest.updateInformationPersonal(data)
        .then(() => {
          this.loading.hide();
          this.changeAction(1);
          this.listAllUser(this.pagesAtributes);
          this.alert("success", "Update_Success_0001");
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Realiza la petición para actualizar la información del usurio de sus sucursale asignadas
     * @method
     */
    async updateInfoUserBranchOffice() {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        html: "La información del usuario sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      const { branchOfficesAdd = [], branchOfficesDelete = [] } =
        (await this.$refs.get_listBranchOfficeUser.getListBranchOfficeUser()) ||
        {};
      const {
        idBranchoffice,
        userId,
        mainBranchOffice: { idBranchOffice: mainBranchOfficeId },
        role,
      } = this.modelUser;
      const data = {
        idBranchOffice: idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
        userId,
        mainBranchOfficeId,
        roleId: role,
        branchOfficesAdd,
        branchOfficesDelete,
      };
      try {
        await UserRequest.updateUserBranchOffice(data);
        this.loading.hide();
        this.changeAction(1);
        this.listAllUser(this.pagesAtributes);
        this.alert("success", "Update_Success_0001");
      } catch (error) {
        this.alert("error", error.message);
      } finally {
        this.loading.hide();
      }
    },
    /**
     * Obtiene todos los usuarios de la base de datos y los coloca en una lista.
     * @method
     * @param {Object} pages { currentPage: 0, itemsPerPage: 15, search: ""}
     */
    async listAllUser(pages) {
      this.loading = UtilFront.getLoadding();
      await UserRequest.getAllUser(pages)
        .then((res) => {
          this.listUsers = res.data;
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Realiza la petición para el registro del usuario
     * @method
     */
    async addUser() {
      let branchOfficesAssing = await this.$refs.get_listBranchOfficeUser.getListBranchOfficeUser();
      const { isConfirmed } = await this.$SwalConfirm.fire({
        html:
          "El siguiente usuario sera registrado: </br>" +
          '<div class="pt-2"><b>Nombre: </b>' +
          this.modelUser.name +
          " " +
          this.modelUser.lastName +
          "</br></div>" +
          "<div><b>" +
          "Correo electrónico: " +
          "</b>" +
          this.modelUser.email +
          "</div></br>" +
          "<div><b>Sucursal principal: </b>" +
          this.modelUser.mainBranchOffice.name +
          "</div>" +
          "<div><b>Número de sucursales asignadas: </b>" +
          branchOfficesAssing.branchOfficesAdd.length +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let userInfo = {
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
        mainBranchOfficeId: this.modelUser.mainBranchOffice.idBranchOffice,
        email: this.modelUser.email,
        name: this.modelUser.name,
        lastName: this.modelUser.lastName,
        password: this.modelUser.password,
        passwordConfirm: this.modelUser.passwordConfirm,
        role: this.modelUser.role,
        branchOfficesAdd: branchOfficesAssing.branchOfficesAdd,
      };
      await UserRequest.addUser(userInfo)
        .then(() => {
          this.loading.hide();
          this.changeAction(1);
          this.listAllUser(this.pagesAtributes);
          this.alert("success", "Add_Success_0001");
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Una función que se llama cuando el usuario hace clic en el botón de detalle para consultar la informacion del usuario
     * @method
     * @param {Object} item la información del usuaria a obtener
     */
    async detailUser(item) {
      this.loading = UtilFront.getLoadding();
      await UserRequest.detailUser({ userId: item.userId })
        .then((res) => {
          this.modelUser = res.data;
          this.$refs.detailUserComponent.dialogShow(true);
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Una función que se llama cuando el usuario hace clic en el botón de la columna de estatus, que realiza la modificación del estatus del usuario
     * @method
     * @param {Object} item la información del usuaria a obtener
     */
    async updateStatus(item) {
      this.modelUser = item;
      if (!item.available) {
        const { isConfirmed } = await SwalConfirm.fire({
          icon: "warning",
          title: this.$t("user.text_disable"),
          html: this.$t("user.message_disable", {
            name: item.fullName,
          }),
        });
        if (!isConfirmed) {
          this.modelUser.available = !this.modelUser.available;
          return;
        }
      } else {
        const { isConfirmed } = await SwalConfirm.fire({
          icon: "warning",
          title: this.$t("user.text_enable"),
          html: this.$t("user.message_enable", {
            name: item.fullName,
          }),
        });
        if (!isConfirmed) {
          this.modelUser.available = !this.modelUser.available;
          return;
        }
      }
      this.loading = UtilFront.getLoadding();
      let payload = {
        available: this.modelUser.available ? 1 : 0,
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: UtilFront.getDataUser().idUser,
        userId: this.modelUser.userId,
      };
      UserRequest.updateStatus(payload)
        .then(() => {
          this.alert("success", "Update_Status_0001");
        })
        .catch((error) => {
          this.modelUser.available = !this.modelUser.available;
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Una función que se llama cuando el usuario hace clic en el botón restaurar contraseña, que realiza la modificación de la misma
     * @method
     * @param {Object} item la información del usuaria a obtener
     */
    async resetPassword(item) {
      this.modelUser = item;
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        title: this.$t("user.reset_password"),
        html: this.$t("user.reset_text", {
          name: item.fullName,
          email: item.email,
        }),
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let payload = {
        userId: item.userId,
        idBranchOffice: this.branchOffice.idBranchoffice,
        idUser: this.$UtilFront.getDataUser().idUser,
      };
      UserRequest.resetPasswordUser(payload)
        .then(() => {
          this.alert("success", "Reset_Password_0001");
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Obtiene el listado de los roles en el sistema
     * @method
     */
    async listAllRoles() {
      this.roles_loading = UtilFront.getLoadding();
      this.roles = [];
      try {
        const { data: roles = [] } = await RolRequest.listAll();
        this.roles = roles;
      } catch (error) {
        this.changeAction(1);
        this.alert("error", error.message);
      } finally {
        this.roles_loading.hide();
      }
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    excel() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportUsers({
        id: this.branchOffice.idBranchoffice,
      });
    },
  },
  async created() {
    await this.listAllRoles();
    this.branchOffice = this.branchOfficeState;
    this.action = 1;
    this.updateAction(1);
  },
  watch: {
    branchOfficeState() {
      this.branchOffice = this.branchOfficeState;
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headersBranch = BRANCH_OFFICE_USER();
      this.headers = USERS();
    },
  },
  /**
   * @description Componenetes utilizado en la vista principal de solicitudes
   * @prop {Components} TableUserComponent {@link component:TableUserComponent} - Este es el componente de TableUserComponent que es la tabla principal
   * @prop {Components} FormUserComponent {@link component:FormUserComponent} - Este componente es el formulario para la creacion, modificacion de un usuario
   * @prop {Common} ButtonAction {@link component:ButtonAction} - Este es el common de ButtonAction que para realizar la diferentes acciones de registrar, modificar
   * @prop {Components} BranchOfficeCommon {@link component:BranchOfficeCommon} - Este es el componente BranchOfficeCommon que se utiliza para el compoente global de sucursales
   * @prop {Components} DetailUserComponent {@link component:DetailUserComponent} - Este es el componente DetailUserComponent que se utiliza para el detalle del usuario
   */
  components: {
    ValidationProvider,
    ValidationObserver,
    TableUserComponent,
    AlertMessageComponent,
    FormUserComponent,
    ButtonAction,
    BranchOfficeCommon,
    DetailUserComponent,
  },
};
</script>
