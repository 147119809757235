<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-body">
          <validation-observer ref="observer" disabled>
            <form @submit.prevent="doAction">
              <div class="row" v-if="showAction(2) || showAction(3)">
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model="model.name"
                      oninput="this.value = this.value.toUpperCase()"
                      :counter="100"
                      :error-messages="errors"
                      :label="$t('label.name')"
                      required
                      autocomplete="off"
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="lastname"
                    rules="required|max:200"
                  >
                    <v-text-field
                      oninput="this.value = this.value.toUpperCase()"
                      v-model="model.lastName"
                      :counter="200"
                      :error-messages="errors"
                      :label="$t('label.lastname')"
                      required
                      autocomplete="off"
                    >
                    </v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|max:60|email"
                  >
                    <v-text-field
                      oninput="this.value = this.value.toLowerCase()"
                      v-model="model.email"
                      data-vv-as="emai"
                      type="email"
                      :counter="60"
                      :error-messages="errors"
                      :label="$t('label.e-mail')"
                      required
                      autocomplete="off"
                    ></v-text-field>
                  </validation-provider>
                </div>
              </div>

              <div class="row" v-if="showAction(2)">
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required|max:50|min:6"
                  >
                    <v-text-field
                      autocomplete="new-password"
                      v-model="model.password"
                      :counter="50"
                      :append-icon="
                        visible ? 'feather icon-eye-off' : 'feather icon-eye'
                      "
                      @click:append="() => (visible = !visible)"
                      :type="visible ? 'password' : 'text'"
                      :error-messages="errors"
                      :label="$t('label.password')"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="password_confirmation"
                    rules="required|max:50|confirmed:password|min:6"
                  >
                    <v-text-field
                      v-model="model.passwordConfirm"
                      :counter="50"
                      :error-messages="errors"
                      :label="$t('label.password_confirmation')"
                      required
                      autocomplete="off"
                      :append-icon="
                        visibleConfirmation
                          ? 'feather icon-eye-off'
                          : 'feather icon-eye'
                      "
                      @click:append="
                        () => (visibleConfirmation = !visibleConfirmation)
                      "
                      :type="visibleConfirmation ? 'password' : 'text'"
                    >
                    </v-text-field>
                  </validation-provider>
                </div>
              </div>
              <div class="col-12 pl-0 pb-0 pt-5" v-if="showAction(2)">
                <h6>
                  <v-icon class="pb-2" v-text="'mdi-numeric-2-box-outline'" />
                  Información complementaria
                </h6>
              </div>
              <div class="row" v-if="showAction(2) || showAction(4)">
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <v-autocomplete
                      auto-select-first
                      :items="roles"
                      :no-data-text="$t('label.no_results')"
                      attach
                      v-model="model.role"
                      :error-messages="errors"
                      clearable
                      required
                      :label="$t('label.role')"
                    >
                    </v-autocomplete>
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="branch_main"
                  >
                    <v-autocomplete
                      :error-messages="errors"
                      no-data-text="Sin resultados"
                      :items="main.mainBranchOffice"
                      item-value="idBranchoffice"
                      auto-select-first
                      clearable
                      required
                      item-text="name"
                      v-model="model.mainBranchOffice"
                      return-object
                      label="Sucursal Principal"
                    ></v-autocomplete>
                  </validation-provider>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @component FormUserComponent
 * @desc Este es el componente de FormUserComponent que muestra el formulario para realizar el registro y la modificación
 * @author Jesus Teran
 */
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  /** @vprop {Object} [model='{}'] - Tiene los datos que nececita el formulario  */
  /** @vprop {Object} [branchOffice='{}'] - En caso de la modificación esta tiene la sucursales asignadas del usuario a modificar */
  /** @vprop {Function} [showAction] - Esta funcion se utiliza para validar en acción se encuentra ya sea sea si el usuario esta realizado un registro o una modificación */
  /** @vprop {Array} [roles] - La lista de los roles que se le pueden asignar a un usuario */
  /** @vprop {Object} [main] - Se utiliza para almacenar la sucursal global del usuario a modificar */
  props: {
    model: Object,
    branchOffice: Object,
    showAction: Function,
    roles: Array,
    main: Object,
  },
  data() {
    return {
      sending: false,
      loading: false,
      visible: true,
      visibleConfirmation: true,
    };
  },
  methods: {
    /**
     * Realiza la validación de el formulario
     * @method
     */
    async validateForm() {
      return await this.$refs.observer.validate();
    },
  },
  watch: {
    "main.mainBranchOffice": {
      handler: function(val) {
        let flag = false;
        val.filter((branchOffice) => {
          if (
            branchOffice.idBranchOffice ==
            this.model.mainBranchOffice?.idBranchOffice
          ) {
            flag = true;
          }
        });
        if (!flag) this.model.mainBranchOffice = null;
      },
      deep: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style>
.theme--light.v-data-table > .v-data-table__wrapper {
  max-height: 520px;
}
</style>
