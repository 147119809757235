var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observer",attrs:{"disabled":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doAction.apply(null, arguments)}}},[(_vm.showAction(2) || _vm.showAction(3))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"oninput":"this.value = this.value.toUpperCase()","counter":100,"error-messages":errors,"label":_vm.$t('label.name'),"required":"","autocomplete":"off"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,false,1295307548)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"oninput":"this.value = this.value.toUpperCase()","counter":200,"error-messages":errors,"label":_vm.$t('label.lastname'),"required":"","autocomplete":"off"},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}],null,false,184327711)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:60|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"oninput":"this.value = this.value.toLowerCase()","data-vv-as":"emai","type":"email","counter":60,"error-messages":errors,"label":_vm.$t('label.e-mail'),"required":"","autocomplete":"off"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,false,2695643473)})],1)]):_vm._e(),(_vm.showAction(2))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"password","rules":"required|max:50|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","counter":50,"append-icon":_vm.visible ? 'feather icon-eye-off' : 'feather icon-eye',"type":_vm.visible ? 'password' : 'text',"error-messages":errors,"label":_vm.$t('label.password'),"required":""},on:{"click:append":function () { return (_vm.visible = !_vm.visible); }},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,false,1182689385)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required|max:50|confirmed:password|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"error-messages":errors,"label":_vm.$t('label.password_confirmation'),"required":"","autocomplete":"off","append-icon":_vm.visibleConfirmation
                        ? 'feather icon-eye-off'
                        : 'feather icon-eye',"type":_vm.visibleConfirmation ? 'password' : 'text'},on:{"click:append":function () { return (_vm.visibleConfirmation = !_vm.visibleConfirmation); }},model:{value:(_vm.model.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.model, "passwordConfirm", $$v)},expression:"model.passwordConfirm"}})]}}],null,false,4013438762)})],1)]):_vm._e(),(_vm.showAction(2))?_c('div',{staticClass:"col-12 pl-0 pb-0 pt-5"},[_c('h6',[_c('v-icon',{staticClass:"pb-2",domProps:{"textContent":_vm._s('mdi-numeric-2-box-outline')}}),_vm._v(" Información complementaria ")],1)]):_vm._e(),(_vm.showAction(2) || _vm.showAction(4))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.roles,"no-data-text":_vm.$t('label.no_results'),"attach":"","error-messages":errors,"clearable":"","required":"","label":_vm.$t('label.role')},model:{value:(_vm.model.role),callback:function ($$v) {_vm.$set(_vm.model, "role", $$v)},expression:"model.role"}})]}}],null,false,2620326201)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"required","name":"branch_main"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"no-data-text":"Sin resultados","items":_vm.main.mainBranchOffice,"item-value":"idBranchoffice","auto-select-first":"","clearable":"","required":"","item-text":"name","return-object":"","label":"Sucursal Principal"},model:{value:(_vm.model.mainBranchOffice),callback:function ($$v) {_vm.$set(_vm.model, "mainBranchOffice", $$v)},expression:"model.mainBranchOffice"}})]}}],null,false,1625534660)})],1)]):_vm._e()])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }