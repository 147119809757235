var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelTable.content,"search":_vm.search,"height":"auto","fixed-header":"","item-key":"email","dense":"","single-line":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"server-items-length":_vm.modelTable.totalElements,"options":_vm.options,"page":_vm.page,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar...","disabled":_vm.loading_search},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.available",fn:function(ref){
    var item = ref.item;
return [(_vm.userStatus)?_c('v-switch',{staticClass:"hand",attrs:{"color":"success","hide-details":"","dense":"","inset":""},on:{"change":function($event){return _vm.confirmchangeStatus(item)}},model:{value:(item.available),callback:function ($$v) {_vm.$set(item, "available", $$v)},expression:"item.available"}}):_vm._e(),(!_vm.userStatus)?_c('div',[_vm._v(" "+_vm._s(item.available ? "Activo" : "Inactivo")+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detailUser(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye mr-1"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(_vm.resetPassword)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.confirmResetPassword(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-rotate-ccw"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.reset_password")))])]):_vm._e(),(_vm.isBranchOffice || _vm.userUpdate)?_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var menu = ref.on;
    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"feather icon-more-vertical"})])]}}],null,true)},[_c('span',[_vm._v("Más opciones")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":"","nav":""}},[(_vm.userUpdate)?_c('v-list-item',{on:{"click":function($event){return _vm.changeAction(3, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"mdi mdi-square-edit-outline mr-1"}),_vm._v(" Editar información de usuario")])],1)],1):_vm._e(),(_vm.isBranchOffice)?_c('v-list-item',{on:{"click":function($event){return _vm.changeAction(4, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('i',{staticClass:"feather icon-clipboard mr-1"}),_vm._v(" Editar asignación sucursales")])],1)],1):_vm._e()],1)],1):_vm._e(),(!_vm.isDetail && !_vm.resetPassword && !_vm.userUpdate && !_vm.isBranchOffice)?_c('div',[_vm._v(" - ")]):_vm._e()]}}],null,true)}),(true)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }